<template>

  <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Color Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-3 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-9">
            <input type="text" id="txtcolor" class="form-control" placeholder="Enter Color Name here..." maxlength="100" v-if="color" v-model="color.name" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-3 col-form-label font-weight-semibold">Status:</label>
          <div class="col-md-9">
            <input type="text" class="form-control" placeholder="Enter print name here..." maxlength="100" v-if="color" v-model="color.status">
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
import FabButton from '@/components/core/FabButton.vue'
import {userService} from '@/store/auth-header.js'
export default {
  name: 'ColorForm',
  data () {
    return {
      color: JSON.parse('{"id":0,"name":"","status":"Active","type":0}')
    }
  },
  component: {
    FabButton
  },
  props: {
    mycolor: {
      type: Object,
      default: () => JSON.parse('{"id":0,"name":"","status":"Active","type":0}')
    }
  },
  beforeMount () {
    this.color = this.mycolor; // save props data to itself's data and deal with tms
  },
  created () {
    this.$data.color = JSON.parse('{"id":0,"name":"","status":"Active","type":0}')
  },
  mounted () {
    $('#txtcolor').focus();
  },
  methods: {
    closeWindow() {
      // this.$parent.$parent.closeModal();
      this.$emit('color_window_closed');
    },
    saveDocument(){
      const self = this;

      if(self.$data.color.name.trim().length < 1){
        alert("Invalid Name");
        return
      }

      const requestOptions = {
        method:  (self.$data.color.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.color)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/color`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success"} );
          self.color = JSON.parse('{"id":0,"name":"","status":"Active","type":0}');
          this.$emit('color_saved');
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>

</style>
